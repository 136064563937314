import Styles from '../styles.module.scss';
import illustration from '../../../assets/meetup/illustration.svg';
import withComponentName from '../../../decorators/withComponentName';

const MeetupTopSection = () => (
  <div className={Styles['meetup-top-section']}>
    <div className={Styles.section}>
      <div className={Styles['flex-wrapper']}>
        <div className={Styles['text-section']}>
          <h1>UK and International Value Investing Clubs</h1>
          <p>
            Investment demands fundamental research and due diligence.
            Share and learn ideas, discuss management and market events,
            and analyse financial statements with other smart investors.
          </p>
          <p>
            This group helps all members build strong portfolios of long-term investments
            and better understand the markets in general
          </p>
        </div>
        <div className={Styles['image-section']}>
          <img src={illustration} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default withComponentName(MeetupTopSection);
